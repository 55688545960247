<template>
    <div class="absolute -top-3 z-60 bg-white hidden md:block min-w-[250px]" v-if="active"
         @mouseenter="menuShown(true)" @mouseleave="menuShown(false)">
        <div class="flex flex-col m-4 px-1">
            <div class="my-2">
                <Link @click="menuShown(false)" :href="`/hakkimizda`"><h3 class="text-lg font-semibold">Hakkımızda</h3></Link>
            </div>
            <div class="my-2">
                <Link @click="menuShown(false)" :href="`/olcu-kilavuzu`"><h3 class="text-lg font-semibold">Ölçü Kılavuzu</h3></Link>
            </div>
            <hr>
            <div class="my-2">
                <Link @click="menuShown(false)" :href="`/kategoriler/tum-urunler`"><h3 class="text-lg font-semibold">Tüm Ürünler</h3></Link>
            </div>

            <template v-for="(menu, index) in menuItems" :key="index">
                <div class="mb-4">
                    <h3 class="font-semibold text-lg">
                        <Link v-if="menu.data.category_slug" @click="menuShown(false)" :href="`/kategoriler/${menu.data.category_slug}`">
                            {{ menu.label }}
                        </Link>
                        <a target="_blank" v-if="menu.data.url" @click="menuShown(false)" :href="`${menu.data.url}`">
                            {{ menu.label }}
                        </a>
                    </h3>
                    <ul class="mt-2 text-xs ml-1" v-for="(menuSubItem, subIndex) in menu.children" :key="subIndex">
                        <li class="text-gray-600">
                            <Link v-if="menuSubItem.data.category_slug" @click="menuShown(false)" :href="`/kategoriler/${menuSubItem.data.category_slug}`">
                                {{ menuSubItem.label }}
                            </Link>
                            <a target="_blank" v-if="menuSubItem.data.url" @click="menuShown(false)" :href="`${menuSubItem.data.url}`">
                                {{ menuSubItem.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <!--            <div>-->
            <!--                <Link href="https://kiralamini.com/" target="_blank">-->
            <!--                    <img class="w-full max-w-[150px]"-->
            <!--                         src="https://kiralamini.com/wp-content/uploads/2023/02/cropped-logo-2048x344.png" alt="">-->
            <!--                </Link>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    name: "Menu",
    props: {
        active: {
            type: Boolean,
            default: true
        },
        menuItems: Object
    },
    emits: ["menuchange"],
    methods: {
        menuShown(val) {
            //console.log('close');
            this.$emit("menuchange", val);
        }
    },
    components: {
        Link
    }
};
</script>

<style scoped></style>
